<template>
  <div class="cont  main sc-width">
    <div class="box">
      <div class="title">Edit Document</div>
      <Form
        ref="edit"
        :model="edit"
        :rules="editRules"
        class="document-form"
        :label-width="200"
      >
        <FormItem label="Document Name" prop="name">
          <Input v-model="edit.name" />
        </FormItem>

        <FormItem label="Type" prop="type">
          <Select v-model="edit.type">
            <Option v-for="item in document_type" :key="item" :value="item">{{
              item
            }}</Option>
          </Select>
        </FormItem>

        <FormItem label="Extra Type" prop="extraType">
          <Select v-model="edit.extraType">
            <Option
              v-for="item in document_extra_type"
              :key="item"
              :value="item"
              >{{ item }}</Option
            >
          </Select>
        </FormItem>

        <FormItem label="File" prop="url">
          <div v-if="!(file || url)" class="upload" @click="$refs.file.click()">
            <div class="upload-inner">
              <input
                @change="handleUpload"
                v-show="false"
                type="file"
                ref="file"
              />
              <Icon class="icon" type="ios-cloud-upload" size="52"></Icon>
              <p>Click here to upload</p>
            </div>
          </div>
          <div class="upload" v-else>
            <div v-if="uplodLoading">
              Uploading...
            </div>
            <div v-else>
              <div v-if="url">
                <Alert type="success">File upload successful</Alert>
                <Button @click="removeFile">Choose Again</Button>
              </div>
            </div>
          </div>
        </FormItem>

        <FormItem>
          <Button :loading="editLoading" type="primary" @click="editSubmit"
            >Submit</Button
          >
        </FormItem>
      </Form>
    </div>
    <div class="form-foot"></div>
  </div>
</template>

<script>
import api from "@/api";
const { _queryDocument, _editDocument, _queryDocumentRelatedInfo } = api;
import S3 from "aws-s3";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  name: "documents",
  created() {
    const id = this.$route.params.id;
    this.id = id;
    _queryDocument({ id })
      .then(response => {
        const {
          data: { url, type, name, extra }
        } = response;

        this.url = url;
        this.edit.name = name;
        this.edit.type = type;
        this.edit.extraType = extra.type;
      })
      .catch(error => {
        console.log(error);
      });
    _queryDocumentRelatedInfo()
      .then(response => {
        const {
          data: { document_extra_type, document_type }
        } = response;

        this.document_extra_type = document_extra_type;
        this.document_type = document_type;
      })
      .catch(error => {
        console.log(error);
      });
  },
  data() {
    return {
      id: null,
      document_type: [],
      document_extra_type: [],
      file: null,
      url: "",
      uplodLoading: false,
      edit: {
        name: "",
        type: "",
        extraType: ""
      },
      editLoading: false,
      editRules: {
        name: [
          {
            required: true
          }
        ],
        type: [
          {
            required: true
          }
        ],
        extraType: [
          {
            required: true
          }
        ],
        url: [
          {
            required: true,
            validator: (rule, value, callback) => {
              if (this.url) {
                callback();
              } else {
                callback(new Error("file is required"));
              }
            }
          }
        ]
      }
    };
  },

  methods: {
    back(type) {
      this.$router.push({ path: "/company-profile", query: { type: type } });
    },
    removeFile() {
      this.file = null;
      this.url = "";
    },

    handleUpload(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          this.file = file;
          this.upload();
        }
      }
    },

    upload() {
      this.uplodLoading = true;
      uploader
        .uploadFile(this.file)
        .then(data => {
          this.url = data.location;
        })
        .catch(() => {
          this.$Message.error("upload error");
        })
        .finally(() => {
          this.uplodLoading = false;
          this.$refs.edit.validateField("url");
        });
    },

    editSubmit() {
      this.$refs.edit.validate(valid => {
        if (valid) {
          this.editLoading = true;
          _editDocument({
            id: this.id,
            name: this.edit.name,
            type: this.edit.type,
            url: this.url,
            extra: {
              type: this.edit.extraType
            }
          })
            .then(() => {
              this.$Message.success("success");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.editLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
@media (max-width: 1400px) and (min-width: 1200px) {
  .ivu-input {
    width: 80%;
  }
}
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}

.cont {
  margin: 78px auto 0 auto;
  /*background-image: url('../../assets/my-profile.png');*/
  .box {
    background: #fff;
    padding-bottom: 20px;
    margin-bottom: 30px;
    border-radius: 15px;
    position: relative;
    top: 40px;
    /*width: 400px;*/
    .document-form {
      width: 1130px;
      margin: 30px auto 0 auto;
    }
    .title {
      height: 60px;
      background: #fff;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      font-size: 20px;

      font-weight: 700;
      color: #292929;
      line-height: 18px;
      padding-top: 20px;
      display: flex;
      justify-content: center;
    }
    .upload {
      width: 70%;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 20px;
      cursor: pointer;

      .upload-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .icon {
          color: @primary-color;
        }
      }
    }
  }
}

.top {
  .back-request {
    width: 200px;
    height: 40px;
    background: #ef7f21;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    margin-top: 50px;
    margin-bottom: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    top: 39px;
    .pic {
      width: 8px;
      height: 10px;
      margin-right: 10px;
      margin-top: 2px;
      img {
        width: 20px;
        height: 20px;
        position: relative;
        top: 8px;
        left: 0px;
      }
    }

    .word {
      position: relative;
      top: 9px;
      font-size: 18px;

      font-weight: 500;
      color: #ffffff;
      line-height: 18px;
    }
  }
}
  .form-foot{
    height: 40px;
  }
</style>
<style scoped>
.form-box >>> .ivu-card-bordered {
  border: none;
}

.document-form >>> .ivu-form-item-label {
  font-size: 16px;

  font-weight: 400;
  color: #292929;
  line-height: 18px;
}
.ivu-steps {
  width: 1150px;
  margin: 0 auto;
  justify-content: space-between;
}
.ivu-btn-primary {
  width: 200px;
  height: 34px;
  background: linear-gradient(90deg, #f5a210, #ef8126);
  border-radius: 5px;
  font-size: 18px;

  font-weight: 400;
  color: #ffffff;
  line-height: 18px;
}
.ivu-form-item-content {
  margin-bottom: 40px;
}
.ivu-input-wrapper {
  width: 70%;
}
.ivu-select {
  width: 70%;
}
</style>
